import React from "react"
import { EstatePlanContainer } from "./janowoStyle.js"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Virtual = ({ intl }) => (
  <>
    <AnchorLink to="/pl/oferta/osiedle-janowo-park/#virtual--tour"></AnchorLink>

    <EstatePlanContainer className="virtual">
      <Container className="virtual--walk__wrapper" id="virtual--tour">
        <Row>
          <Col className="no-padding virtual--walk__title" xs="12">
            <h1>
              {intl.formatMessage({
                id: "home.main.more.virtual.walk.subtitle",
              })}
            </h1>
          </Col>
          <Col xs="12">
            <div className="virtual--walk__container">
              <iframe
                width="100%"
                height="100%"
                src="https://roundme.com/embed/585859/"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </EstatePlanContainer>
  </>
)

export default injectIntl(Virtual)
