import React, { useState } from "react"
import { Accordion, Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"

import { FlatItemContainer } from "@/components/search/searchStyles"
import { MobileButton } from "@/components/shared/button/button"
import { Link as Scroller } from "react-scroll"

const EstateParkingList = ({ intl, id, title, items, showSize = false }) => {
  function onClickAsk(item) {
    const name = `${item.building} / ${item.hall} / ${item.number}`
    document.getElementById("myModal").style.display = "block"
    document.getElementsByClassName("code")[0].innerText = name
    document.getElementById("projectName").value = item.name
  }
  const max = 4
  const [showAll, setShowAll] = useState(false)
  const filteredItems = () => {
    if (showAll) {
      return items
    }
    return items.slice(0, max)
  }

  return (
    <EstateParkingContainer>
      <a name={id} />
      <Container>
        {title && (
          <Row>
            <Col>
              <h2 className="title">{title}</h2>
            </Col>
          </Row>
        )}

        <Row className="legend">
          <Col md={3} lg={2}>
            Budynek
          </Col>
          <Col md={3} lg={2}>
            Hala
          </Col>
          <Col md={3} lg={2}>
            {showSize ? "Numer komórki" : "Numer miejsca"}
          </Col>
          <Col md={3} lg={3}>
            {showSize && "Metraż"}&nbsp;
          </Col>
          <Col lg={3} className="hide-on-mobile">
            Cena
          </Col>
        </Row>

        {filteredItems().map(item => (
          <FlatItemContainer>
            <>
              <Accordion>
                <Accordion.Toggle as={"div"} eventKey="0">
                  <Row className="results">
                    <Col xs={3} lg={2}>
                      {item.building}
                    </Col>
                    <Col xs={3} lg={2}>
                      {item.hall}
                    </Col>
                    <Col xs={3} lg={2}>
                      {item.number}
                    </Col>
                    <Col xs={3} lg={3}>
                      {item.size && (
                        <>
                          { Number(item.size).toFixed(2)}&nbsp;m<sup>2</sup>
                        </>
                      )}
                    </Col>
                    <Col xs={3} lg={3} className="hide-on-mobile">
                      <Row className="actions">
                        <Col lg={8}>
                          <MobileButton
                            handleAction={() => onClickAsk(item)}
                            innerText={intl.formatMessage({
                              id: "offer.janowo.list.options.ask",
                            })}
                            bg="#94C835"
                            color="white"
                            border="white"
                            arrow={false}
                          />
                        </Col>
                        <Col lg={4}>
                          <a className="details">
                            {intl.formatMessage({
                              id: "offer.janowo.list.options.details",
                            })}
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Row className="results no-padding">
                    <Col xs={0} lg={8} xl={8} />
                    <Col xs={12} lg={2} xl={2}>
                      {item.file && (
                        <a
                          className="results-item"
                          href={item.file}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          <MobileButton
                            handleAction={() => {}}
                            innerText="RZUT"
                            bg="#5D5D5D"
                            border="#5D5D5D"
                            color="white"
                            arrow={true}
                            smallArrow={true}
                          />
                        </a>
                      )}

                    </Col>
                    <Col xs={12} lg={2} xl={2}>
                      <MobileButton
                        handleAction={() => onClickAsk(item)}
                        innerText="Zapytaj o cenę"
                        bg="#94C835"
                        color="white"
                        border="white"
                        arrow={false}
                      />
                    </Col>
                  </Row>
                </Accordion.Collapse>
              </Accordion>
            </>
          </FlatItemContainer>
        ))}

        {!showAll && items.length > max && (
          <Row>
            <Col xs="12">
              <div className="show-more">
                <MobileButton
                  innerText={intl.formatMessage({
                    id: "offer.janowo.list.results.more",
                  })}
                  bg="#94C835"
                  color="white"
                  border="#94C835"
                  arrow={false}

                  handleAction={() => {
                    setShowAll(true)
                  }}
                />
              </div>
            </Col>
          </Row>
        )}

      </Container>
    </EstateParkingContainer>
  )
}

export default injectIntl(EstateParkingList)

export const EstateParkingContainer = styled.div`
  padding: 80px 0 40px;

  & + & {
    padding-top: 0;
  }

  .hide-on-mobile {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .legend {
    //margin: 0 -15px;
    padding: 15px 0;
    text-transform: uppercase;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      height: 1px;
      border-bottom: 1px solid #dadada;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
  .results {
    padding: 20px 0;
    font-size: 16px;
    font-weight: 200;
    align-items: center;

    .details {
      position: relative;
      cursor: pointer;
      &::after {
        position: absolute;

        transform: rotate(225deg) translate(-50%, -50%);
        content: "";
        background-color: transparent;
        width: 10px;
        height: 10px;
        border-left: 1px solid #5d5d5d;
        border-bottom: 1px solid #5d5d5d;
      }
    }

    .actions {
      align-items: center;

      > div {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          text-align: right;
        }
      }
    }

    button {
      font-size: 16px;
      height: auto;
      padding: 6px 0px;
      font-weight: 200;
    }
  }
  .show-more {

    padding: 40px 0 40px;
    max-width: 300px;
    margin: auto;
    position: relative;
    ::after {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 26px;
      cursor: pointer;
      color: white;
      content: "+";
      transform: translate(-50%, -50%);
    }

  }
`
