import styled from "styled-components"
import { Container, Row } from "react-bootstrap"

export const SearchContainer = styled(Container)`
  padding: 0 !important;

  /* .collapse.show {
    background-color: #f8f8f8;
  } */
  .mobile-only {
    display: none;
    @media (max-width: 1200px) {
      display: block;
    }
  }
  .container-border {
    h2 {
      padding: 25px 0 20px;
      font-size: 24px;
      position: relative;
      margin: 0 0 8px;
      ::after {
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #dadada;
        position: absolute;
      }
      @media only screen and (min-width: 1200px) {
        ::after {
          display: none;
        }
      }
    }
  }
  .desktop-container {
    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }
  .mobile-container {
    @media only screen and (min-width: 1200px) {
      display: none;
    }
  }

  .no-results {
    height: 100px;
  }
  .no-padding {
    margin: 0;
    padding: 0;
  }
  .show-more {
    padding: 40px 0 40px;
    max-width: 300px;
    margin: auto;
    position: relative;
    ::after {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 26px;
      cursor: pointer;
      color: white;
      content: "+";
      transform: translate(-50%, -50%);
    }
  }
  .mobile-list-title {
    margin: 100px 0 25px;
  }
  .desktop-container {
    .desktop-list-title {
      padding: 100px 0 50px;
      h1 {
        font-size: 37px;
      }
    }
    .search-filter.active {
      background-color: #94c835;
      border: 1px #94c835 solid;
      color: white;
    }

    .filter-title {
      padding: 0 5px 0 0;
      font-size: 24px;
      font-weight: 500;
      border-bottom: 1px solid #94c835;
      ::after {
        content: "";
        position: absolute;
        bottom: -1px;
        right: 0;
        background-color: white;
        width: 4px;
        height: 10px;
      }
      p {
        position: relative;
        padding: 0 0 10px;
        margin-bottom: 0;
      }
    }
    .filter-title,
    .filter-btn {
      margin-bottom: 15px;
    }
    .search-button {
      padding: 0 5px 0 0;
      height: 100%;
      div {
        height: 100%;
        padding: 0;
        margin: 0 0 5px;
      }
      .btn:disabled {
        opacity: 0.3;
      }
      button.search-filter {
        box-shadow: none;
        line-height: 1.5;
      }
    }
    .search-filter {
      border: 1px #5d5d5d solid;
      background-color: white;
      width: calc(100% - 4px);
      margin: 0 4px 0 0;
      padding: 6px 0;
      font-size: 16px;
      border-radius: 4px;
      height: 100%;
      color: #5d5d5d;
      outline: none;
      :hover {
        border: 1px solid #94c835;
      }
    }
    .search-btn {
      display: flex;
      height: 100%;
      padding: 0;
      margin-top: 10px;
      button {
        height: 55px;
      }
    }
  }
  .offset-col {
    @media only screen and (max-width: 767px) {
      display: none;
    }
    @media only screen and (min-width: 1440px) {
      display: none;
    }
  }
  .buttons-mobile {
    @media only screen and (min-width: 1440px) {
      display: none !important;
    }
  }
  .buttons-desktop {
    display: none;
    align-content: flex-end;
    flex-wrap: wrap;
    padding: 0 0 5px 10px;
    button {
      height: 38px;
      margin-bottom: 5px;
    }
    @media only screen and (min-width: 1440px) {
      display: flex;
    }
  }
  .legend-info.wicz {
    div {
      margin: auto;
    }
  }
  .legend-info {
    display: none;
    position: relative;
    padding: 0 5px 0 10px;
    .padding-left {
      //padding-left: 29px;
    }
    @media only screen and (min-width: 1200px) {
      display: flex;
    }

    div {
      padding: 0 0 30px;
      margin: 0;
      font-size: 20px;
    }
    ::after {
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dadada;
      position: absolute;
    }
  }
  .date-desktop,
  .sides-desktop {
    padding: 0 5px !important;
  }
  .date-desktop,
  .sides-desktop,
  .mobile-results {
    @media only screen and (min-width: 1200px) {
      display: none;
    }
  }
  .mobile-results-button {
    @media only screen and (min-width: 1200px) {
      display: block;
    }
  }
  .mobile-results {
    padding: 20px 5px;
    @media only screen and (max-width: 476px) {
      font-size: 16px;
    }
  }
  .btns-offset {
    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }
  .desktop-results {
    padding: 0 5px 0 10px;
    .id-item::after {
      @media only screen and (min-width: 1440px) {
        display: none;
      }
    }
    .results-item {
      font-size: 16px !important;
      font-weight: 200;
      &.icon button {
        padding: 2px 0;
      }
    }
    .details {
      text-align: center;
      position: relative;
      cursor: pointer;

      &.options {
        display: flex;
        width: 100%;
        gap: 5px;
      }
      //::after {
      //  position: absolute;
      //
      //  transform: rotate(225deg) translate(-50%, -50%);
      //  content: "";
      //  background-color: transparent;
      //  width: 10px;
      //  height: 10px;
      //  border-left: 1px solid #5d5d5d;
      //  border-bottom: 1px solid #5d5d5d;
      //}
    }
    .results-item {
      button {
        font-size: 16px;
        height: auto;
        padding: 6px 0;
        font-weight: 200;

        @media only screen and (max-width: 1440px) {
          font-size: 14px;
        }
      }
      span {
        font-weight: 200 !important;
      }
    }
  }

  .desktop-results {
    @media only screen and (max-width: 1199px) {
      display: none;
    }
    .row {
      div {
        padding: 0;
      }
    }
  }
  .date-mobile,
  .sides-mobile {
    @media only screen and (max-width: 1199px) {
      display: none;
    }
    .row {
      div {
        padding: 0;
      }
    }
  }
  .sides {
    div {
      border-radius: 4px;
    }
    @media only screen and (min-width: 1440px) {
      div {
        margin: 0 4px 4px 0 !important;
        font-size: 12px !important;
      }
    }
  }
`

export const MobileContainer = styled(Row)`
  .offset-bottom {
    height: 100px;
    min-height: 100px;
  }
  .search-title {
    padding: 0 0 20px;
    margin-bottom: 20px;
    h1 {
      font-size: 26px !important;
    }
    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dadada;
      content: "";
    }
  }
  .search-button,
  .filter-title {
    padding: 0;
    p,
    div {
      margin: 0;
      font-size: 20px;
    }
    div {
      display: flex;
      padding: 0;
    }
  }
  .search-filter {
    border: 1px solid #dadada;
    color: #5d5d5d;
    background-color: transparent;
    min-width: calc(100% - 10px);
    margin-bottom: 10px;
    height: 61px;
    /* :hover {
      border: 1px solid #94c835;
    } */
  }
  .active {
    background-color: #94c835;
    border: 1px #94c835 solid;
    color: white;
  }
  .search-button {
    padding: 20px 0;
    margin-bottom: 20px;
    position: relative;
    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dadada;
      content: "";
    }
    p {
      margin: 0;
    }
  }
`

export const SearchButton = styled.button``

export const FlatPlan = styled.div``

export const FlatItemContainer = styled(Row)`
  font-size: 20px;
  margin: 0;
  position: relative;
  ::after {
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #dadada;
    position: absolute;
  }
  .sold {
    background-color: #f8f8f8;
  }
  .accordion {
    width: 100%;
  }
  .no-padding {
    padding: 0;
  }
  .id-item {
    position: relative;
    @media only screen and (max-width: 1199px) {
      ::after {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%) rotate(225deg);
        content: "";
        background-color: transparent;
        width: 10px;
        height: 10px;
        border-left: 2px solid #5d5d5d;
        border-bottom: 2px solid #5d5d5d;
      }
    }
  }
  .options-btn {
    text-align: center;
    .results-item {
      button {
        margin: 10px 0 10px 0;
        width: calc(100% - 10px);
        font-size: 16px;
        height: auto;
        padding: 6px 0;
        font-weight: 200;
        @media only screen and (max-width: 1440px) {
          margin: 5px 0 15px 0;
        }
      }
    }
  }
  .table-result-item {
    margin: auto;
    padding: 20px 0 !important;
  }
  .results-item {
    .sides {
      color: #94c835;
      text-transform: uppercase;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      div {
        margin: 4px 8px 4px 0;
        padding: 6px 4px 5px;
        background-color: #e7f3d2;
      }
    }
  }
  .main-results {
    display: flex;
    justify-content: space-between;
    .results-item {
      position: relative;
      :nth-child(4),
      :nth-child(2),
      :nth-child(3) {
        color: #5d5d5d;
        font-weight: 200;
        ::after {
          right: calc(100% + 14px);
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
          height: 20px;
          width: 1px;
          background-color: black;
          content: "";
        }
      }
    }
  }
  .button-actions {
    display: flex;
    width: 100%;
    gap: 4px;
    > div {
      width: 100%;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: 360px) {
    .results-item {
      button {
        font-size: 16px;
      }
      :nth-child(4),
      :nth-child(2),
      :nth-child(3) {
        ::after {
          right: calc(100% + 5px) !important;
          top: 50%;
        }
      }
    }
  }
  .results-item.date {
    font-weight: 200;
    font-size: 20px;
    span {
      font-weight: 500;
    }
  }
`
export const ResultsWindow = styled.div``
