export const storageOJP = [
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "2.28",
  //   size: 3.39,
  //   file: '/assets/files/OJP/storage/Hala_2-3_komorka_lokatorska_2.28.pdf'
  // },
  {
    building: "2-3",
    hall: "H2",
    number: "3.10",
    size: 3.49,
    file: "/assets/files/OJP/storage/Hala_2-3_komorka_lokatorska_3.10.pdf",
  },
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "3.12",
  //   size: 3.10,
  //   file: '/assets/files/OJP/storage/Hala_2-3_komorka_lokatorska_3.12.pdf'
  // },
  // {
  //   building: "4-5",
  //   hall: "H3",
  //   number: "2.37",
  //   size: 3.41,
  //   file: '/assets/files/OJP/storage/Hala_4-5_komorka_lokatorska_2.37.pdf'
  // },
  // {
  //   building: "9",
  //   hall: "H6",
  //   number: "3.3",
  //   size: 5.56,
  //   file: "/assets/files/OJP/storage/Hala_9_komorka_lokatorska_3.3.pdf",
  // },
]
